import React from "react"
import LazyLoad from "react-lazyload"
import AccountIcon from "features/AccountIcon"
import style from "./style.module.scss"
import PickupScene from "models/PickupScene"
import PickupSceneList from "utils/contentful/PickupSceneList"
import { useTunnelClient, } from "hooks/TunnelClient"

type Props = {
  list: PickupSceneList
  scenes: PickupScene[]
}

/**
 * Pickup scene list for mobile with vertical rectangles.
 */
export default function PickupSceneListComponent ({
  list,
  scenes,
}: Props) {
  const { playScene, } = useTunnelClient()

  return (
    <div className={style.frame}>
      <div className={style.header}>
        {list.labelImage ? (
          <div
            className={style.image}
            style={{
              backgroundImage: `url(${list.labelImage.url})`,
            }}
          />
        ) : (
          <div className={style.text}>{list.title}</div>
        )}
        <div className={style.description}>{list.description}</div>
      </div>
      <div className={style.container}>
        <div
          className={style.list}
          style={{
            width: `${scenes.length * 164}px`,
          }}
        >
          {scenes.map((scene) => {
            let { title, } = scene
            if (title && title.length > 50) {
              title = `${title.substr(0, 50)}...`
            }
            return (
              <div key={scene.id} className={style.card} onClick={() => playScene(scene.id)}>
                <LazyLoad height='100%'>
                  <img
                    alt='thumbnail'
                    className={style.thumbnail}
                    src={scene.image.url}
                    onLoad={(e) => {
                      e.currentTarget.style.opacity = "1"
                    }}
                  />
                </LazyLoad>
                <div className={style.meta}>
                  <div className={style.title}>{title}</div>
                  <div className={style.accountIconContainer}>
                    <AccountIcon
                      username={scene.user?.username || ""}
                      profileImageUrl={scene.user?.profileImageUrl || null}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
