import React, { useEffect, useCallback, } from "react"
import style from "./style.module.scss"
import { selectState as appState, deleteBrowingHistory, } from "app/App/redux"
import { initialize, selectState, } from "./redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import PickupSceneListComponent from "./PickupSceneList"
import AdList from "./AdList"
import Billboard from "./Billboard"
import LoadingPlaceholder from "./LoadingPlaceholder"
import { tunnelClient, } from "globalInstance"

const mapDispatch = { initialize, deleteBrowingHistory, }
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector>

/**
 * Top Page. Holds Picked up scenes like Staff picks.
 */
function TopPage ({ initialize, deleteBrowingHistory, }: Props) {
  /**
   * Opens ad in brower. Sends URL open request to Unity.
   */
  const onAdClick = useCallback(async (url: string) => {
    await tunnelClient.openExternalPage(url)
  }, [])

  const { service, } = useSelector(appState)
  const state = useSelector(selectState)
  const {
    initializing,
    initialized,
    pickupSceneLists,
    pickupSceneListMap,
    ads,
    staffPicksScenes,
  } = state

  useEffect(() => {
    if (service === "STYLY_MOBILE") {
      deleteBrowingHistory()
    }
  }, [deleteBrowingHistory, service])

  useEffect(() => {
    if (!initialized) {
      initialize()
    }
  }, [initialize, initialized])

  if (initializing || !initialized) {
    return (
      <LoadingPlaceholder />
    )
  }

  return (
    <div className={style.frame}>
      <Billboard scenes={staffPicksScenes} />
      <AdList ads={ads} onAdClick={onAdClick}/>
      {pickupSceneLists.map(list => {
        const scenes = pickupSceneListMap[list.id]
        if (scenes) {
          return (
            <PickupSceneListComponent
              key={list.id}
              list={list}
              scenes={scenes}
            />
          )
        } else {
          return <></>
        }
      })}
    </div>
  )
}

export default connector(TopPage)
