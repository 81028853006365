/* tslint:disable */
/* eslint-disable */
/**
 * STYLY API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddCommentRequest
 */
export interface AddCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCommentRequest
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface AddMylistResponse
 */
export interface AddMylistResponse {
    /**
     * 
     * @type {number}
     * @memberof AddMylistResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof Asset
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'thumbnail_url': string;
    /**
     * 
     * @type {Platform}
     * @memberof Asset
     */
    'platform': Platform;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface AssetCompleteRequest
 */
export interface AssetCompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetCompleteRequest
     */
    'unity_version': string;
    /**
     * 
     * @type {string}
     * @memberof AssetCompleteRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AssetCompleteRequest
     */
    'asset_register_hash': string;
}
/**
 * 
 * @export
 * @interface AssetList
 */
export interface AssetList {
    /**
     * 
     * @type {Array<Asset>}
     * @memberof AssetList
     */
    'assets': Array<Asset>;
    /**
     * 
     * @type {number}
     * @memberof AssetList
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface AssetSignedUrlRequest
 */
export interface AssetSignedUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetSignedUrlRequest
     */
    'unity_version': string;
}
/**
 * 
 * @export
 * @interface AssetSignedUrlResponse
 */
export interface AssetSignedUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof AssetSignedUrlResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetSignedUrlResponse
     */
    'package_url': string;
    /**
     * 
     * @type {string}
     * @memberof AssetSignedUrlResponse
     */
    'thumbnail_url': string;
    /**
     * 
     * @type {string}
     * @memberof AssetSignedUrlResponse
     */
    'asset_register_hash': string;
    /**
     * 
     * @type {Platform}
     * @memberof AssetSignedUrlResponse
     */
    'platform': Platform;
}
/**
 * 
 * @export
 * @interface Conversion
 */
export interface Conversion {
    /**
     * 
     * @type {string}
     * @memberof Conversion
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof Conversion
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Conversion
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Conversion
     */
    'thumbnail_url': string;
    /**
     * 
     * @type {string}
     * @memberof Conversion
     */
    'model_url': string;
    /**
     * 
     * @type {number}
     * @memberof Conversion
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof Conversion
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Conversion
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface Conversion1
 */
export interface Conversion1 {
    /**
     * 
     * @type {string}
     * @memberof Conversion1
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof Conversion1
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Conversion1
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Conversion1
     */
    'thumbnail_url': string;
    /**
     * 
     * @type {string}
     * @memberof Conversion1
     */
    'model_url': string;
    /**
     * 
     * @type {number}
     * @memberof Conversion1
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof Conversion1
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Conversion1
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface ConversionList
 */
export interface ConversionList {
    /**
     * 
     * @type {Array<Conversion1>}
     * @memberof ConversionList
     */
    'conversions': Array<Conversion1>;
    /**
     * 
     * @type {number}
     * @memberof ConversionList
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface CreateSceneRequest
 */
export interface CreateSceneRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSceneRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSceneRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSceneRequest
     */
    'blueprint': string;
}
/**
 * 
 * @export
 * @interface CreateSessionRequest
 */
export interface CreateSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSessionRequest
     */
    'scene_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateShortenUrlRequest
 */
export interface CreateShortenUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateShortenUrlRequest
     */
    'long_url': string;
}
/**
 * 
 * @export
 * @interface CreateShortenUrlResponse
 */
export interface CreateShortenUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateShortenUrlResponse
     */
    'shorten_url': string;
}
/**
 * 
 * @export
 * @interface CreateTagsRequest
 */
export interface CreateTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTagsRequest
     */
    'texts': Array<string>;
}
/**
 * 
 * @export
 * @interface EmailChangeWithCodeRequest
 */
export interface EmailChangeWithCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailChangeWithCodeRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface FileList
 */
export interface FileList {
    /**
     * 
     * @type {Array<any>}
     * @memberof FileList
     */
    'files': Array<any>;
    /**
     * 
     * @type {number}
     * @memberof FileList
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface FollowCheckResponse
 */
export interface FollowCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FollowCheckResponse
     */
    'following': boolean;
}
/**
 * 
 * @export
 * @interface IncrementViewRequest
 */
export interface IncrementViewRequest {
    /**
     * 
     * @type {string}
     * @memberof IncrementViewRequest
     */
    'platform_type': IncrementViewRequestPlatformTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IncrementViewRequest
     */
    'scene_view_count_hash': string;
    /**
     * 
     * @type {string}
     * @memberof IncrementViewRequest
     */
    'access_date': string;
}

export const IncrementViewRequestPlatformTypeEnum = {
    Smartphone: 'smartphone',
    Mr: 'mr',
    StandaloneVr: 'standalone-vr',
    PcVr: 'pc-vr',
    Web: 'web'
} as const;

export type IncrementViewRequestPlatformTypeEnum = typeof IncrementViewRequestPlatformTypeEnum[keyof typeof IncrementViewRequestPlatformTypeEnum];

/**
 * 
 * @export
 * @interface JoinSessionResponse
 */
export interface JoinSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof JoinSessionResponse
     */
    'global_ip': string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof Location
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'updated_at': string;
    /**
     * 
     * @type {Array<Scene>}
     * @memberof Location
     */
    'scenes': Array<Scene>;
}
/**
 * 
 * @export
 * @interface LocationList
 */
export interface LocationList {
    /**
     * 
     * @type {Array<Location>}
     * @memberof LocationList
     */
    'locations': Array<Location>;
    /**
     * 
     * @type {number}
     * @memberof LocationList
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface LocationRequest
 */
export interface LocationRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LocationRequest
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationRequest
     */
    'scene_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface Login400Response
 */
export interface Login400Response {
    /**
     * 
     * @type {string}
     * @memberof Login400Response
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Login400Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {number}
     * @memberof LoginResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'username': string;
}
/**
 * Full user properties. This object is given to only login user himself/herself. 
 * @export
 * @interface LoginUser
 */
export interface LoginUser {
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'api_key': string;
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    'plan': number;
    /**
     * 
     * @type {UserFeatures}
     * @memberof LoginUser
     */
    'features': UserFeatures;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'profile_image_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'facebook_id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'instagram_id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'twitter_id': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'site_url': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    'youtube_channel_url': string;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'current': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'new': string;
}
/**
 * 
 * @export
 * @interface PasswordResetWithCodeRequest
 */
export interface PasswordResetWithCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetWithCodeRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetWithCodeRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Platform
 */
export interface Platform {
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    'Android': string;
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    'iOS': string;
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    'OSX': string;
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    'WebGL': string;
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    'Windows': string;
}
/**
 * 
 * @export
 * @interface RefreshApiKeyResponse
 */
export interface RefreshApiKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof RefreshApiKeyResponse
     */
    'api_key': string;
}
/**
 * 
 * @export
 * @interface Scene
 */
export interface Scene {
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof Scene
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'thumbnail_tiny_url': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'thumbnail_small_url': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'thumbnail_middle_url': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'thumbnail_large_url': string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Scene
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    'access_level': number;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    'published': boolean;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'published_at': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'updated_at': string;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    'good_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    'gooded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    'mylisted': boolean;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    'custom_logo_url': string | null;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    'view_count_total'?: number | null;
}
/**
 * 
 * @export
 * @interface SceneAccessTokenCreationRequest
 */
export interface SceneAccessTokenCreationRequest {
    /**
     * 
     * @type {number}
     * @memberof SceneAccessTokenCreationRequest
     */
    'expires_in': number;
}
/**
 * 
 * @export
 * @interface SceneAccessTokenCreationResponse
 */
export interface SceneAccessTokenCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof SceneAccessTokenCreationResponse
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface SceneComment
 */
export interface SceneComment {
    /**
     * 
     * @type {number}
     * @memberof SceneComment
     */
    'id': number;
    /**
     * 
     * @type {User}
     * @memberof SceneComment
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof SceneComment
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface SceneCommentList
 */
export interface SceneCommentList {
    /**
     * 
     * @type {Array<SceneComment>}
     * @memberof SceneCommentList
     */
    'comments': Array<SceneComment>;
    /**
     * 
     * @type {number}
     * @memberof SceneCommentList
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface SceneContent
 */
export interface SceneContent {
    /**
     * 
     * @type {string}
     * @memberof SceneContent
     */
    'blueprint': string;
}
/**
 * 
 * @export
 * @interface SceneCreationRequest
 */
export interface SceneCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof SceneCreationRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SceneCreationRequest
     */
    'template_scene_id': string;
}
/**
 * 
 * @export
 * @interface SceneCreationResponse
 */
export interface SceneCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof SceneCreationResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SceneIdSearchRequest
 */
export interface SceneIdSearchRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SceneIdSearchRequest
     */
    'scene_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface SceneList
 */
export interface SceneList {
    /**
     * 
     * @type {Array<SceneListItem>}
     * @memberof SceneList
     */
    'scenes': Array<SceneListItem>;
}
/**
 * 
 * @export
 * @interface SceneListItem
 */
export interface SceneListItem {
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof SceneListItem
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'thumbnail_tiny_url': string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'thumbnail_small_url': string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'thumbnail_middle_url': string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'thumbnail_large_url': string;
    /**
     * 
     * @type {number}
     * @memberof SceneListItem
     */
    'access_level': number;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    'published': boolean;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    'published_at': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    'gooded': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    'mylisted': boolean;
    /**
     * 
     * @type {number}
     * @memberof SceneListItem
     */
    'view_count_total'?: number | null;
}
/**
 * 
 * @export
 * @interface SceneMeta
 */
export interface SceneMeta {
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'id': string;
    /**
     * 
     * @type {User}
     * @memberof SceneMeta
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'thumbnail_tiny_url': string;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'thumbnail_small_url': string;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'thumbnail_middle_url': string;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'thumbnail_large_url': string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof SceneMeta
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {number}
     * @memberof SceneMeta
     */
    'access_level': number;
    /**
     * 
     * @type {boolean}
     * @memberof SceneMeta
     */
    'published': boolean;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'published_at': string | null;
    /**
     * 
     * @type {number}
     * @memberof SceneMeta
     */
    'good_count': number;
    /**
     * 
     * @type {string}
     * @memberof SceneMeta
     */
    'custom_logo_url': string | null;
}
/**
 * 
 * @export
 * @interface ScenePublishInfo
 */
export interface ScenePublishInfo {
    /**
     * 
     * @type {string}
     * @memberof ScenePublishInfo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ScenePublishInfo
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScenePublishInfo
     */
    'tag_ids': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScenePublishInfo
     */
    'access_level': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScenePublishInfo
     */
    'access_user_ids': Array<number>;
    /**
     * URL of registered PNG logo file
     * @type {string}
     * @memberof ScenePublishInfo
     */
    'custom_logo_url'?: string;
}
/**
 * 
 * @export
 * @interface ScenePublishRequest
 */
export interface ScenePublishRequest {
    /**
     * 
     * @type {string}
     * @memberof ScenePublishRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ScenePublishRequest
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScenePublishRequest
     */
    'tag_ids': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScenePublishRequest
     */
    'access_level': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScenePublishRequest
     */
    'access_user_ids': Array<number>;
    /**
     * PNG Image data to register as a custom logo of the scene. Base64 encoded 512 x 512 px.
     * @type {string}
     * @memberof ScenePublishRequest
     */
    'custom_logo_data'?: string;
}
/**
 * 
 * @export
 * @interface SceneSearchResult
 */
export interface SceneSearchResult {
    /**
     * 
     * @type {Array<SceneListItem>}
     * @memberof SceneSearchResult
     */
    'scenes': Array<SceneListItem>;
    /**
     * 
     * @type {number}
     * @memberof SceneSearchResult
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface SceneTranslationResult
 */
export interface SceneTranslationResult {
    /**
     * 
     * @type {TranslationResult}
     * @memberof SceneTranslationResult
     */
    'title': TranslationResult;
    /**
     * 
     * @type {TranslationResult}
     * @memberof SceneTranslationResult
     */
    'description': TranslationResult;
}
/**
 * 
 * @export
 * @interface SendEmailChangeEmailRequest
 */
export interface SendEmailChangeEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendEmailChangeEmailRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface SendEmailResetPasswordRequest
 */
export interface SendEmailResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof SendEmailResetPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'display_id': string;
    /**
     * 
     * @type {User}
     * @memberof Session
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'description': string;
    /**
     * 
     * @type {Array<SceneMeta>}
     * @memberof Session
     */
    'scenes': Array<SceneMeta>;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface SessionList
 */
export interface SessionList {
    /**
     * 
     * @type {Array<Session>}
     * @memberof SessionList
     */
    'sessions': Array<Session>;
    /**
     * 
     * @type {number}
     * @memberof SessionList
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface SignupRequest
 */
export interface SignupRequest {
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface SignupResponse
 */
export interface SignupResponse {
    /**
     * 
     * @type {number}
     * @memberof SignupResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SignupResponse
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof SuccessResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface TagCountResult
 */
export interface TagCountResult {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TagCountResult
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {number}
     * @memberof TagCountResult
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface TagList
 */
export interface TagList {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TagList
     */
    'tags': Array<Tag>;
}
/**
 * 
 * @export
 * @interface TranslationResult
 */
export interface TranslationResult {
    /**
     * 
     * @type {string}
     * @memberof TranslationResult
     */
    'source_language': string;
    /**
     * 
     * @type {string}
     * @memberof TranslationResult
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface UpdateConversionStatusRequest
 */
export interface UpdateConversionStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateConversionStatusRequest
     */
    'jobId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateConversionStatusRequest
     */
    'state': number;
}
/**
 * 
 * @export
 * @interface UpdateSceneRequest
 */
export interface UpdateSceneRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSceneRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSceneRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSceneRequest
     */
    'blueprint': string;
}
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'profile_image_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'facebook_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'instagram_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'twitter_id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'site_url': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'youtube_channel_url': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'followed'?: boolean | null;
}
/**
 * User UI features.
 * @export
 * @interface UserFeatures
 */
export interface UserFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    'can_change_plan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    'can_manage_locations': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    'mobile_skip_top': boolean;
}
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserList
     */
    'users': Array<User>;
}
/**
 * 
 * @export
 * @interface UserProfileRequest
 */
export interface UserProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'facebook_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'instagram_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'twitter_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'site_url': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'youtube_channel_url': string;
}
/**
 * 
 * @export
 * @interface UserSearchResult
 */
export interface UserSearchResult {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserSearchResult
     */
    'users': Array<User>;
    /**
     * 
     * @type {number}
     * @memberof UserSearchResult
     */
    'total': number;
}

/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates signed url
         * @param {string} authorization API Key with Bearer keyword.
         * @param {AssetSignedUrlRequest} assetSignedUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSignedUrl: async (authorization: string, assetSignedUrlRequest: AssetSignedUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createSignedUrl', 'authorization', authorization)
            // verify required parameter 'assetSignedUrlRequest' is not null or undefined
            assertParamExists('createSignedUrl', 'assetSignedUrlRequest', assetSignedUrlRequest)
            const localVarPath = `/asset/signed_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetSignedUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete asset
         * @param {string} assetId asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('deleteAsset', 'assetId', assetId)
            const localVarPath = `/asset/{asset_id}`
                .replace(`{${"asset_id"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get asset
         * @param {string} assetId asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAsset', 'assetId', assetId)
            const localVarPath = `/asset/{asset_id}`
                .replace(`{${"asset_id"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get own asset list
         * @param {string} [query] Query to search assets.
         * @param {number} [limit] Limit of assets to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAssets: async (query?: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates styly_asset record for Unity Plugin.
         * @param {string} authorization API Key with Bearer keyword.
         * @param {string} assetId asset ID
         * @param {AssetCompleteRequest} assetCompleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAsset: async (authorization: string, assetId: string, assetCompleteRequest: AssetCompleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('registerAsset', 'authorization', authorization)
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('registerAsset', 'assetId', assetId)
            // verify required parameter 'assetCompleteRequest' is not null or undefined
            assertParamExists('registerAsset', 'assetCompleteRequest', assetCompleteRequest)
            const localVarPath = `/asset/{asset_id}/complete`
                .replace(`{${"asset_id"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assetCompleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates signed url
         * @param {string} authorization API Key with Bearer keyword.
         * @param {AssetSignedUrlRequest} assetSignedUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSignedUrl(authorization: string, assetSignedUrlRequest: AssetSignedUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSignedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSignedUrl(authorization, assetSignedUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete asset
         * @param {string} assetId asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAsset(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAsset(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get asset
         * @param {string} assetId asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get own asset list
         * @param {string} [query] Query to search assets.
         * @param {number} [limit] Limit of assets to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyAssets(query?: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyAssets(query, limit, offset, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates styly_asset record for Unity Plugin.
         * @param {string} authorization API Key with Bearer keyword.
         * @param {string} assetId asset ID
         * @param {AssetCompleteRequest} assetCompleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerAsset(authorization: string, assetId: string, assetCompleteRequest: AssetCompleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerAsset(authorization, assetId, assetCompleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates signed url
         * @param {string} authorization API Key with Bearer keyword.
         * @param {AssetSignedUrlRequest} assetSignedUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSignedUrl(authorization: string, assetSignedUrlRequest: AssetSignedUrlRequest, options?: any): AxiosPromise<AssetSignedUrlResponse> {
            return localVarFp.createSignedUrl(authorization, assetSignedUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete asset
         * @param {string} assetId asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(assetId: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteAsset(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get asset
         * @param {string} assetId asset ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(assetId: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.getAsset(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get own asset list
         * @param {string} [query] Query to search assets.
         * @param {number} [limit] Limit of assets to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAssets(query?: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: any): AxiosPromise<AssetList> {
            return localVarFp.getMyAssets(query, limit, offset, sort, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates styly_asset record for Unity Plugin.
         * @param {string} authorization API Key with Bearer keyword.
         * @param {string} assetId asset ID
         * @param {AssetCompleteRequest} assetCompleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAsset(authorization: string, assetId: string, assetCompleteRequest: AssetCompleteRequest, options?: any): AxiosPromise<Asset> {
            return localVarFp.registerAsset(authorization, assetId, assetCompleteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * 
     * @summary Creates signed url
     * @param {string} authorization API Key with Bearer keyword.
     * @param {AssetSignedUrlRequest} assetSignedUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public createSignedUrl(authorization: string, assetSignedUrlRequest: AssetSignedUrlRequest, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).createSignedUrl(authorization, assetSignedUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete asset
     * @param {string} assetId asset ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public deleteAsset(assetId: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).deleteAsset(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get asset
     * @param {string} assetId asset ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getAsset(assetId: string, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).getAsset(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get own asset list
     * @param {string} [query] Query to search assets.
     * @param {number} [limit] Limit of assets to get.
     * @param {number} [offset] Offset of search
     * @param {object} [sort] Sort order column name
     * @param {'asc' | 'desc'} [direction] Sort order direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public getMyAssets(query?: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).getMyAssets(query, limit, offset, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates styly_asset record for Unity Plugin.
     * @param {string} authorization API Key with Bearer keyword.
     * @param {string} assetId asset ID
     * @param {AssetCompleteRequest} assetCompleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public registerAsset(authorization: string, assetId: string, assetCompleteRequest: AssetCompleteRequest, options?: AxiosRequestConfig) {
        return AssetApiFp(this.configuration).registerAsset(authorization, assetId, assetCompleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest Payload to Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            assertParamExists('login', 'loginRequest', loginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignupRequest} signupRequest Payload to Signup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (signupRequest: SignupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signupRequest' is not null or undefined
            assertParamExists('signup', 'signupRequest', signupRequest)
            const localVarPath = `/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest Payload to Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignupRequest} signupRequest Payload to Signup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(signupRequest: SignupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(signupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} loginRequest Payload to Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignupRequest} signupRequest Payload to Signup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signupRequest: SignupRequest, options?: any): AxiosPromise<SignupResponse> {
            return localVarFp.signup(signupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {LoginRequest} loginRequest Payload to Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginRequest: LoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignupRequest} signupRequest Payload to Signup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signup(signupRequest: SignupRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).signup(signupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConversionApi - axios parameter creator
 * @export
 */
export const ConversionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary post zip file
         * @param {File} body Payload including binary data
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertAsset: async (body: File, title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('convertAsset', 'body', body)
            const localVarPath = `/conversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title != null) {
                localVarHeaderParameter['Title'] = String(title);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete asset conversion by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConversion', 'id', id)
            const localVarPath = `/conversion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get asset conversion by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversion', 'id', id)
            const localVarPath = `/conversion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get my conversion list
         * @param {string} [query] Query to search conversions.
         * @param {number} [limit] Limit of assets to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversions: async (query?: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/conversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update status from model converter
         * @param {string} id asset id
         * @param {UpdateConversionStatusRequest} updateConversionStatusRequest Payload to update conversion status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversionStatus: async (id: string, updateConversionStatusRequest: UpdateConversionStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConversionStatus', 'id', id)
            // verify required parameter 'updateConversionStatusRequest' is not null or undefined
            assertParamExists('updateConversionStatus', 'updateConversionStatusRequest', updateConversionStatusRequest)
            const localVarPath = `/conversion/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConversionStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversionApi - functional programming interface
 * @export
 */
export const ConversionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary post zip file
         * @param {File} body Payload including binary data
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertAsset(body: File, title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertAsset(body, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete asset conversion by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConversion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConversion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get asset conversion by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversion1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get my conversion list
         * @param {string} [query] Query to search conversions.
         * @param {number} [limit] Limit of assets to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversions(query?: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversions(query, limit, offset, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update status from model converter
         * @param {string} id asset id
         * @param {UpdateConversionStatusRequest} updateConversionStatusRequest Payload to update conversion status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConversionStatus(id: string, updateConversionStatusRequest: UpdateConversionStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConversionStatus(id, updateConversionStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversionApi - factory interface
 * @export
 */
export const ConversionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversionApiFp(configuration)
    return {
        /**
         * 
         * @summary post zip file
         * @param {File} body Payload including binary data
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertAsset(body: File, title?: string, options?: any): AxiosPromise<Conversion> {
            return localVarFp.convertAsset(body, title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete asset conversion by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConversion(id: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteConversion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get asset conversion by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversion(id: string, options?: any): AxiosPromise<Conversion1> {
            return localVarFp.getConversion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get my conversion list
         * @param {string} [query] Query to search conversions.
         * @param {number} [limit] Limit of assets to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversions(query?: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: any): AxiosPromise<ConversionList> {
            return localVarFp.getConversions(query, limit, offset, sort, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update status from model converter
         * @param {string} id asset id
         * @param {UpdateConversionStatusRequest} updateConversionStatusRequest Payload to update conversion status.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConversionStatus(id: string, updateConversionStatusRequest: UpdateConversionStatusRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.updateConversionStatus(id, updateConversionStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversionApi - object-oriented interface
 * @export
 * @class ConversionApi
 * @extends {BaseAPI}
 */
export class ConversionApi extends BaseAPI {
    /**
     * 
     * @summary post zip file
     * @param {File} body Payload including binary data
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversionApi
     */
    public convertAsset(body: File, title?: string, options?: AxiosRequestConfig) {
        return ConversionApiFp(this.configuration).convertAsset(body, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete asset conversion by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversionApi
     */
    public deleteConversion(id: string, options?: AxiosRequestConfig) {
        return ConversionApiFp(this.configuration).deleteConversion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get asset conversion by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversionApi
     */
    public getConversion(id: string, options?: AxiosRequestConfig) {
        return ConversionApiFp(this.configuration).getConversion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get my conversion list
     * @param {string} [query] Query to search conversions.
     * @param {number} [limit] Limit of assets to get.
     * @param {number} [offset] Offset of search
     * @param {object} [sort] Sort order key
     * @param {'asc' | 'desc'} [direction] Sort order direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversionApi
     */
    public getConversions(query?: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return ConversionApiFp(this.configuration).getConversions(query, limit, offset, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update status from model converter
     * @param {string} id asset id
     * @param {UpdateConversionStatusRequest} updateConversionStatusRequest Payload to update conversion status.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversionApi
     */
    public updateConversionStatus(id: string, updateConversionStatusRequest: UpdateConversionStatusRequest, options?: AxiosRequestConfig) {
        return ConversionApiFp(this.configuration).updateConversionStatus(id, updateConversionStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get uploaded files
         * @param {string} type Image or Music.
         * @param {string} [query] Query to search assets.
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles: async (type: string, query?: string, sort?: object, direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getFiles', 'type', type)
            const localVarPath = `/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload file
         * @param {File} body Payload including binary data
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (body: File, filename?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('uploadFile', 'body', body)
            const localVarPath = `/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename != null) {
                localVarHeaderParameter['Filename'] = String(filename);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get uploaded files
         * @param {string} type Image or Music.
         * @param {string} [query] Query to search assets.
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiles(type: string, query?: string, sort?: object, direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiles(type, query, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload file
         * @param {File} body Payload including binary data
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(body: File, filename?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(body, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary get uploaded files
         * @param {string} type Image or Music.
         * @param {string} [query] Query to search assets.
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(type: string, query?: string, sort?: object, direction?: 'asc' | 'desc', options?: any): AxiosPromise<FileList> {
            return localVarFp.getFiles(type, query, sort, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload file
         * @param {File} body Payload including binary data
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(body: File, filename?: string, options?: any): AxiosPromise<UploadFileResponse> {
            return localVarFp.uploadFile(body, filename, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary get uploaded files
     * @param {string} type Image or Music.
     * @param {string} [query] Query to search assets.
     * @param {object} [sort] Sort order column name
     * @param {'asc' | 'desc'} [direction] Sort order direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFiles(type: string, query?: string, sort?: object, direction?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFiles(type, query, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload file
     * @param {File} body Payload including binary data
     * @param {string} [filename] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadFile(body: File, filename?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadFile(body, filename, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ロケーションの新規作成
         * @param {LocationRequest} [locationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (locationRequest?: LocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロケーションの削除
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLocation', 'id', id)
            const localVarPath = `/location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロケーションの取得
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocation', 'id', id)
            const localVarPath = `/location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロケーション一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ロケーションの更新
         * @param {string} id ロケーションID
         * @param {LocationRequest} [locationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (id: string, locationRequest?: LocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLocation', 'id', id)
            const localVarPath = `/location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ロケーションの新規作成
         * @param {LocationRequest} [locationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(locationRequest?: LocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(locationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロケーションの削除
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロケーションの取得
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロケーション一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLocations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLocations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ロケーションの更新
         * @param {string} id ロケーションID
         * @param {LocationRequest} [locationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(id: string, locationRequest?: LocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(id, locationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @summary ロケーションの新規作成
         * @param {LocationRequest} [locationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(locationRequest?: LocationRequest, options?: any): AxiosPromise<Location> {
            return localVarFp.createLocation(locationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロケーションの削除
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLocation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロケーションの取得
         * @param {string} id ロケーションID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(id: string, options?: any): AxiosPromise<Location> {
            return localVarFp.getLocation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロケーション一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLocations(options?: any): AxiosPromise<LocationList> {
            return localVarFp.listLocations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ロケーションの更新
         * @param {string} id ロケーションID
         * @param {LocationRequest} [locationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(id: string, locationRequest?: LocationRequest, options?: any): AxiosPromise<Location> {
            return localVarFp.updateLocation(id, locationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @summary ロケーションの新規作成
     * @param {LocationRequest} [locationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public createLocation(locationRequest?: LocationRequest, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).createLocation(locationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロケーションの削除
     * @param {string} id ロケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public deleteLocation(id: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).deleteLocation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロケーションの取得
     * @param {string} id ロケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocation(id: string, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getLocation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロケーション一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public listLocations(options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).listLocations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ロケーションの更新
     * @param {string} id ロケーションID
     * @param {LocationRequest} [locationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public updateLocation(id: string, locationRequest?: LocationRequest, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).updateLocation(id, locationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MylistApi - axios parameter creator
 * @export
 */
export const MylistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a scene from myList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (sceneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('_delete', 'sceneId', sceneId)
            const localVarPath = `/user/mylist/default/{scene_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a scene to myList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add: async (sceneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('add', 'sceneId', sceneId)
            const localVarPath = `/user/mylist/default/{scene_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets mylisted scenes of a login user
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMylistedScenes: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/mylist/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MylistApi - functional programming interface
 * @export
 */
export const MylistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MylistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a scene from myList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(sceneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a scene to myList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async add(sceneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMylistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.add(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets mylisted scenes of a login user
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMylistedScenes(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMylistedScenes(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MylistApi - factory interface
 * @export
 */
export const MylistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MylistApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a scene from myList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(sceneId: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp._delete(sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a scene to myList
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        add(sceneId: string, options?: any): AxiosPromise<AddMylistResponse> {
            return localVarFp.add(sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets mylisted scenes of a login user
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMylistedScenes(limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.getMylistedScenes(limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MylistApi - object-oriented interface
 * @export
 * @class MylistApi
 * @extends {BaseAPI}
 */
export class MylistApi extends BaseAPI {
    /**
     * 
     * @summary Delete a scene from myList
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MylistApi
     */
    public _delete(sceneId: string, options?: AxiosRequestConfig) {
        return MylistApiFp(this.configuration)._delete(sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a scene to myList
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MylistApi
     */
    public add(sceneId: string, options?: AxiosRequestConfig) {
        return MylistApiFp(this.configuration).add(sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets mylisted scenes of a login user
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MylistApi
     */
    public getMylistedScenes(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return MylistApiFp(this.configuration).getMylistedScenes(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SceneApi - axios parameter creator
 * @export
 */
export const SceneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add Scene Thumbnail
         * @param {string} sceneId Scene ID
         * @param {File} body Payload including binary data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSceneThumbnail: async (sceneId: string, body: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('addSceneThumbnail', 'sceneId', sceneId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addSceneThumbnail', 'body', body)
            const localVarPath = `/scene/{scene_id}/thumbnail`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMyScene: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyMyScene', 'id', id)
            const localVarPath = `/scene/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        copyMySceneDeprecated: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('copyMySceneDeprecated', 'id', id)
            const localVarPath = `/scene/scene/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Scene
         * @param {CreateSceneRequest} createSceneRequest Payload to create for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSceneRequest: CreateSceneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSceneRequest' is not null or undefined
            assertParamExists('create', 'createSceneRequest', createSceneRequest)
            const localVarPath = `/scene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSceneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSceneFromTemplate: async (sceneCreationRequest: SceneCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneCreationRequest' is not null or undefined
            assertParamExists('createNewSceneFromTemplate', 'sceneCreationRequest', sceneCreationRequest)
            const localVarPath = `/scene/new_from_template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sceneCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createNewSceneFromTemplateDeprecated: async (sceneCreationRequest: SceneCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneCreationRequest' is not null or undefined
            assertParamExists('createNewSceneFromTemplateDeprecated', 'sceneCreationRequest', sceneCreationRequest)
            const localVarPath = `/scene/scene/new_from_template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sceneCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScene: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScene', 'id', id)
            const localVarPath = `/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteSceneDeprecated: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSceneDeprecated', 'id', id)
            const localVarPath = `/scene/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets own scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyScenes: async (query?: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scene/my_scenes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishInfo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPublishInfo', 'id', id)
            const localVarPath = `/scene/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPublishInfoDeprecated: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPublishInfoDeprecated', 'id', id)
            const localVarPath = `/scene/scene/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScene: async (id: string, accessToken?: string, sceneFields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScene', 'id', id)
            const localVarPath = `/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }

            if (sceneFields !== undefined) {
                localVarQueryParameter['scene_fields'] = sceneFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneContent: async (id: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSceneContent', 'id', id)
            const localVarPath = `/scene/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSceneContentDeprecated: async (id: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSceneContentDeprecated', 'id', id)
            const localVarPath = `/scene/scene/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSceneDeprecated: async (id: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSceneDeprecated', 'id', id)
            const localVarPath = `/scene/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneTranslation: async (lang: string, id: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getSceneTranslation', 'lang', lang)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSceneTranslation', 'id', id)
            const localVarPath = `/scene/{id}/translated`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSceneTranslationDeprecated: async (lang: string, id: string, accessToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getSceneTranslationDeprecated', 'lang', lang)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSceneTranslationDeprecated', 'id', id)
            const localVarPath = `/scene/scene/{id}/translated`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['access_token'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByIds: async (sceneIdSearchRequest: SceneIdSearchRequest, sceneFields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneIdSearchRequest' is not null or undefined
            assertParamExists('getScenesByIds', 'sceneIdSearchRequest', sceneIdSearchRequest)
            const localVarPath = `/scene/id_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sceneFields !== undefined) {
                localVarQueryParameter['scene_fields'] = sceneFields;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sceneIdSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Scenes by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByUserId: async (id: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenesByUserId', 'id', id)
            const localVarPath = `/user/{id}/scenes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets scenes by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getScenesByUserIdDeprecated: async (id: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenesByUserIdDeprecated', 'id', id)
            const localVarPath = `/scene/user/{id}/scenes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary increment view count of a scene
         * @param {string} sceneId Scene ID
         * @param {IncrementViewRequest} incrementViewRequest Payload to increment view count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementView: async (sceneId: string, incrementViewRequest: IncrementViewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('incrementView', 'sceneId', sceneId)
            // verify required parameter 'incrementViewRequest' is not null or undefined
            assertParamExists('incrementView', 'incrementViewRequest', incrementViewRequest)
            const localVarPath = `/scene/{scene_id}/increment_view`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(incrementViewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishRequest} scenePublishRequest Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish: async (id: string, scenePublishRequest: ScenePublishRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publish', 'id', id)
            // verify required parameter 'scenePublishRequest' is not null or undefined
            assertParamExists('publish', 'scenePublishRequest', scenePublishRequest)
            const localVarPath = `/scene/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenePublishRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishRequest} scenePublishRequest Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        publishDeprecated: async (id: string, scenePublishRequest: ScenePublishRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishDeprecated', 'id', id)
            // verify required parameter 'scenePublishRequest' is not null or undefined
            assertParamExists('publishDeprecated', 'scenePublishRequest', scenePublishRequest)
            const localVarPath = `/scene/scene/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenePublishRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {'published_at' | 'created_at' | 'good_count'} [sort] Sort key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (query?: string, limit?: number, offset?: number, sort?: 'published_at' | 'created_at' | 'good_count', direction?: 'asc' | 'desc', sceneFields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scene/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (sceneFields !== undefined) {
                localVarQueryParameter['scene_fields'] = sceneFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Scene
         * @param {string} id Scene ID
         * @param {UpdateSceneRequest} updateSceneRequest Payload to update for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, updateSceneRequest: UpdateSceneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateSceneRequest' is not null or undefined
            assertParamExists('update', 'updateSceneRequest', updateSceneRequest)
            const localVarPath = `/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSceneRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneApi - functional programming interface
 * @export
 */
export const SceneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SceneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add Scene Thumbnail
         * @param {string} sceneId Scene ID
         * @param {File} body Payload including binary data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSceneThumbnail(sceneId: string, body: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSceneThumbnail(sceneId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyMyScene(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyMyScene(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async copyMySceneDeprecated(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyMySceneDeprecated(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Scene
         * @param {CreateSceneRequest} createSceneRequest Payload to create for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSceneRequest: CreateSceneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSceneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewSceneFromTemplate(sceneCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createNewSceneFromTemplateDeprecated(sceneCreationRequest: SceneCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewSceneFromTemplateDeprecated(sceneCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScene(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScene(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteSceneDeprecated(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSceneDeprecated(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets own scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyScenes(query?: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyScenes(query, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishInfo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenePublishInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPublishInfoDeprecated(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenePublishInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishInfoDeprecated(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScene(id: string, accessToken?: string, sceneFields?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScene(id, accessToken, sceneFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSceneContent(id: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneContent(id, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getSceneContentDeprecated(id: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneContentDeprecated(id, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getSceneDeprecated(id: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneDeprecated(id, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSceneTranslation(lang: string, id: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneTranslationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneTranslation(lang, id, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getSceneTranslationDeprecated(lang: string, id: string, accessToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneTranslationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSceneTranslationDeprecated(lang, id, accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, sceneFields?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenesByIds(sceneIdSearchRequest, sceneFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Scenes by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenesByUserId(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenesByUserId(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets scenes by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getScenesByUserIdDeprecated(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenesByUserIdDeprecated(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary increment view count of a scene
         * @param {string} sceneId Scene ID
         * @param {IncrementViewRequest} incrementViewRequest Payload to increment view count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incrementView(sceneId: string, incrementViewRequest: IncrementViewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incrementView(sceneId, incrementViewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishRequest} scenePublishRequest Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publish(id: string, scenePublishRequest: ScenePublishRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publish(id, scenePublishRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishRequest} scenePublishRequest Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async publishDeprecated(id: string, scenePublishRequest: ScenePublishRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishDeprecated(id, scenePublishRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {'published_at' | 'created_at' | 'good_count'} [sort] Sort key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(query?: string, limit?: number, offset?: number, sort?: 'published_at' | 'created_at' | 'good_count', direction?: 'asc' | 'desc', sceneFields?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(query, limit, offset, sort, direction, sceneFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Scene
         * @param {string} id Scene ID
         * @param {UpdateSceneRequest} updateSceneRequest Payload to update for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, updateSceneRequest: UpdateSceneRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateSceneRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SceneApi - factory interface
 * @export
 */
export const SceneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SceneApiFp(configuration)
    return {
        /**
         * 
         * @summary add Scene Thumbnail
         * @param {string} sceneId Scene ID
         * @param {File} body Payload including binary data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSceneThumbnail(sceneId: string, body: File, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.addSceneThumbnail(sceneId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMyScene(id: string, options?: any): AxiosPromise<SceneCreationResponse> {
            return localVarFp.copyMyScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        copyMySceneDeprecated(id: string, options?: any): AxiosPromise<SceneCreationResponse> {
            return localVarFp.copyMySceneDeprecated(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Scene
         * @param {CreateSceneRequest} createSceneRequest Payload to create for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSceneRequest: CreateSceneRequest, options?: any): AxiosPromise<Scene> {
            return localVarFp.create(createSceneRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: any): AxiosPromise<SceneCreationResponse> {
            return localVarFp.createNewSceneFromTemplate(sceneCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createNewSceneFromTemplateDeprecated(sceneCreationRequest: SceneCreationRequest, options?: any): AxiosPromise<SceneCreationResponse> {
            return localVarFp.createNewSceneFromTemplateDeprecated(sceneCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScene(id: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteSceneDeprecated(id: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteSceneDeprecated(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets own scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyScenes(query?: string, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.getMyScenes(query, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishInfo(id: string, options?: any): AxiosPromise<ScenePublishInfo> {
            return localVarFp.getPublishInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPublishInfoDeprecated(id: string, options?: any): AxiosPromise<ScenePublishInfo> {
            return localVarFp.getPublishInfoDeprecated(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScene(id: string, accessToken?: string, sceneFields?: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.getScene(id, accessToken, sceneFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneContent(id: string, accessToken?: string, options?: any): AxiosPromise<SceneContent> {
            return localVarFp.getSceneContent(id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets xml of a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSceneContentDeprecated(id: string, accessToken?: string, options?: any): AxiosPromise<SceneContent> {
            return localVarFp.getSceneContentDeprecated(id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSceneDeprecated(id: string, accessToken?: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.getSceneDeprecated(id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSceneTranslation(lang: string, id: string, accessToken?: string, options?: any): AxiosPromise<SceneTranslationResult> {
            return localVarFp.getSceneTranslation(lang, id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets translated meta results.
         * @param {string} lang language
         * @param {string} id Scene ID
         * @param {string} [accessToken] Time limited JWT token
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSceneTranslationDeprecated(lang: string, id: string, accessToken?: string, options?: any): AxiosPromise<SceneTranslationResult> {
            return localVarFp.getSceneTranslationDeprecated(lang, id, accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, sceneFields?: string, options?: any): AxiosPromise<SceneList> {
            return localVarFp.getScenesByIds(sceneIdSearchRequest, sceneFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Scenes by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByUserId(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.getScenesByUserId(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets scenes by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getScenesByUserIdDeprecated(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.getScenesByUserIdDeprecated(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary increment view count of a scene
         * @param {string} sceneId Scene ID
         * @param {IncrementViewRequest} incrementViewRequest Payload to increment view count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementView(sceneId: string, incrementViewRequest: IncrementViewRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.incrementView(sceneId, incrementViewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishRequest} scenePublishRequest Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(id: string, scenePublishRequest: ScenePublishRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.publish(id, scenePublishRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishRequest} scenePublishRequest Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        publishDeprecated(id: string, scenePublishRequest: ScenePublishRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.publishDeprecated(id, scenePublishRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {'published_at' | 'created_at' | 'good_count'} [sort] Sort key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {string} [sceneFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(query?: string, limit?: number, offset?: number, sort?: 'published_at' | 'created_at' | 'good_count', direction?: 'asc' | 'desc', sceneFields?: string, options?: any): AxiosPromise<SceneSearchResult> {
            return localVarFp.search(query, limit, offset, sort, direction, sceneFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Scene
         * @param {string} id Scene ID
         * @param {UpdateSceneRequest} updateSceneRequest Payload to update for Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, updateSceneRequest: UpdateSceneRequest, options?: any): AxiosPromise<Scene> {
            return localVarFp.update(id, updateSceneRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneApi - object-oriented interface
 * @export
 * @class SceneApi
 * @extends {BaseAPI}
 */
export class SceneApi extends BaseAPI {
    /**
     * 
     * @summary add Scene Thumbnail
     * @param {string} sceneId Scene ID
     * @param {File} body Payload including binary data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public addSceneThumbnail(sceneId: string, body: File, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).addSceneThumbnail(sceneId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make a copy of a scene
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public copyMyScene(id: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).copyMyScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make a copy of a scene
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public copyMySceneDeprecated(id: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).copyMySceneDeprecated(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Scene
     * @param {CreateSceneRequest} createSceneRequest Payload to create for Scene.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public create(createSceneRequest: CreateSceneRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).create(createSceneRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a scene from template.
     * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).createNewSceneFromTemplate(sceneCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a scene from template.
     * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public createNewSceneFromTemplateDeprecated(sceneCreationRequest: SceneCreationRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).createNewSceneFromTemplateDeprecated(sceneCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a scene.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public deleteScene(id: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).deleteScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a scene.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public deleteSceneDeprecated(id: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).deleteSceneDeprecated(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets own scenes
     * @param {string} [query] Query to search scenes.
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getMyScenes(query?: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getMyScenes(query, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets info (meta) of a scene for publish.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getPublishInfo(id: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getPublishInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets info (meta) of a scene for publish.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getPublishInfoDeprecated(id: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getPublishInfoDeprecated(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a scene.
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {string} [sceneFields] Custom request fields separated by comma.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScene(id: string, accessToken?: string, sceneFields?: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getScene(id, accessToken, sceneFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets xml of a scene.
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getSceneContent(id: string, accessToken?: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getSceneContent(id, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets xml of a scene.
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getSceneContentDeprecated(id: string, accessToken?: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getSceneContentDeprecated(id, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a scene.
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getSceneDeprecated(id: string, accessToken?: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getSceneDeprecated(id, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets translated meta results.
     * @param {string} lang language
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getSceneTranslation(lang: string, id: string, accessToken?: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getSceneTranslation(lang, id, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets translated meta results.
     * @param {string} lang language
     * @param {string} id Scene ID
     * @param {string} [accessToken] Time limited JWT token
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getSceneTranslationDeprecated(lang: string, id: string, accessToken?: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getSceneTranslationDeprecated(lang, id, accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets scenes by IDs
     * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
     * @param {string} [sceneFields] Custom request fields separated by comma.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, sceneFields?: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getScenesByIds(sceneIdSearchRequest, sceneFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Scenes by user ID
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScenesByUserId(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getScenesByUserId(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets scenes by user ID
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScenesByUserIdDeprecated(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).getScenesByUserIdDeprecated(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary increment view count of a scene
     * @param {string} sceneId Scene ID
     * @param {IncrementViewRequest} incrementViewRequest Payload to increment view count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public incrementView(sceneId: string, incrementViewRequest: IncrementViewRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).incrementView(sceneId, incrementViewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish a scene
     * @param {string} id Scene ID
     * @param {ScenePublishRequest} scenePublishRequest Payload to update scene with.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public publish(id: string, scenePublishRequest: ScenePublishRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).publish(id, scenePublishRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish a scene
     * @param {string} id Scene ID
     * @param {ScenePublishRequest} scenePublishRequest Payload to update scene with.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public publishDeprecated(id: string, scenePublishRequest: ScenePublishRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).publishDeprecated(id, scenePublishRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches scenes
     * @param {string} [query] Query to search scenes.
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {'published_at' | 'created_at' | 'good_count'} [sort] Sort key
     * @param {'asc' | 'desc'} [direction] Sort order direction
     * @param {string} [sceneFields] Custom request fields separated by comma.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public search(query?: string, limit?: number, offset?: number, sort?: 'published_at' | 'created_at' | 'good_count', direction?: 'asc' | 'desc', sceneFields?: string, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).search(query, limit, offset, sort, direction, sceneFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Scene
     * @param {string} id Scene ID
     * @param {UpdateSceneRequest} updateSceneRequest Payload to update for Scene.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public update(id: string, updateSceneRequest: UpdateSceneRequest, options?: AxiosRequestConfig) {
        return SceneApiFp(this.configuration).update(id, updateSceneRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SceneCommentApi - axios parameter creator
 * @export
 */
export const SceneCommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Comment to a Scene
         * @param {string} sceneId Scene ID
         * @param {AddCommentRequest} addCommentRequest Payload to comment to Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: async (sceneId: string, addCommentRequest: AddCommentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('addComment', 'sceneId', sceneId)
            // verify required parameter 'addCommentRequest' is not null or undefined
            assertParamExists('addComment', 'addCommentRequest', addCommentRequest)
            const localVarPath = `/scene/{scene_id}/comment`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCommentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Comment
         * @param {string} sceneId Scene ID
         * @param {number} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (sceneId: string, commentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('deleteComment', 'sceneId', sceneId)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/scene/{scene_id}/comment/{comment_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)))
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comments
         * @param {string} sceneId Scene ID
         * @param {number} [limit] Limit of comments to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments: async (sceneId: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('getComments', 'sceneId', sceneId)
            const localVarPath = `/scene/{scene_id}/comment`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneCommentApi - functional programming interface
 * @export
 */
export const SceneCommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SceneCommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Comment to a Scene
         * @param {string} sceneId Scene ID
         * @param {AddCommentRequest} addCommentRequest Payload to comment to Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addComment(sceneId: string, addCommentRequest: AddCommentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addComment(sceneId, addCommentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Comment
         * @param {string} sceneId Scene ID
         * @param {number} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(sceneId: string, commentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(sceneId, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get comments
         * @param {string} sceneId Scene ID
         * @param {number} [limit] Limit of comments to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComments(sceneId: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCommentList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComments(sceneId, limit, offset, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SceneCommentApi - factory interface
 * @export
 */
export const SceneCommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SceneCommentApiFp(configuration)
    return {
        /**
         * 
         * @summary Comment to a Scene
         * @param {string} sceneId Scene ID
         * @param {AddCommentRequest} addCommentRequest Payload to comment to Scene.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(sceneId: string, addCommentRequest: AddCommentRequest, options?: any): AxiosPromise<SceneComment> {
            return localVarFp.addComment(sceneId, addCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Comment
         * @param {string} sceneId Scene ID
         * @param {number} commentId Comment ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(sceneId: string, commentId: number, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteComment(sceneId, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comments
         * @param {string} sceneId Scene ID
         * @param {number} [limit] Limit of comments to get.
         * @param {number} [offset] Offset of search
         * @param {object} [sort] Sort order column name
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(sceneId: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: any): AxiosPromise<SceneCommentList> {
            return localVarFp.getComments(sceneId, limit, offset, sort, direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneCommentApi - object-oriented interface
 * @export
 * @class SceneCommentApi
 * @extends {BaseAPI}
 */
export class SceneCommentApi extends BaseAPI {
    /**
     * 
     * @summary Comment to a Scene
     * @param {string} sceneId Scene ID
     * @param {AddCommentRequest} addCommentRequest Payload to comment to Scene.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneCommentApi
     */
    public addComment(sceneId: string, addCommentRequest: AddCommentRequest, options?: AxiosRequestConfig) {
        return SceneCommentApiFp(this.configuration).addComment(sceneId, addCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Comment
     * @param {string} sceneId Scene ID
     * @param {number} commentId Comment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneCommentApi
     */
    public deleteComment(sceneId: string, commentId: number, options?: AxiosRequestConfig) {
        return SceneCommentApiFp(this.configuration).deleteComment(sceneId, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get comments
     * @param {string} sceneId Scene ID
     * @param {number} [limit] Limit of comments to get.
     * @param {number} [offset] Offset of search
     * @param {object} [sort] Sort order column name
     * @param {'asc' | 'desc'} [direction] Sort order direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneCommentApi
     */
    public getComments(sceneId: string, limit?: number, offset?: number, sort?: object, direction?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return SceneCommentApiFp(this.configuration).getComments(sceneId, limit, offset, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SceneGoodApi - axios parameter creator
 * @export
 */
export const SceneGoodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Good to a scene
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGood: async (sceneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('addGood', 'sceneId', sceneId)
            const localVarPath = `/user/good/scene/{scene_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGood: async (sceneId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneId' is not null or undefined
            assertParamExists('deleteGood', 'sceneId', sceneId)
            const localVarPath = `/user/good/scene/{scene_id}`
                .replace(`{${"scene_id"}}`, encodeURIComponent(String(sceneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneGoodApi - functional programming interface
 * @export
 */
export const SceneGoodApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SceneGoodApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Good to a scene
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGood(sceneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGood(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGood(sceneId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGood(sceneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SceneGoodApi - factory interface
 * @export
 */
export const SceneGoodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SceneGoodApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Good to a scene
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGood(sceneId: string, options?: any): AxiosPromise<Scene> {
            return localVarFp.addGood(sceneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Good
         * @param {string} sceneId Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGood(sceneId: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteGood(sceneId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneGoodApi - object-oriented interface
 * @export
 * @class SceneGoodApi
 * @extends {BaseAPI}
 */
export class SceneGoodApi extends BaseAPI {
    /**
     * 
     * @summary Add Good to a scene
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneGoodApi
     */
    public addGood(sceneId: string, options?: AxiosRequestConfig) {
        return SceneGoodApiFp(this.configuration).addGood(sceneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Good
     * @param {string} sceneId Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneGoodApi
     */
    public deleteGood(sceneId: string, options?: AxiosRequestConfig) {
        return SceneGoodApiFp(this.configuration).deleteGood(sceneId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary register session
         * @param {CreateSessionRequest} [createSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession: async (createSessionRequest?: CreateSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Session
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSession', 'id', id)
            const localVarPath = `/session/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSession: async (xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xForwardedFor != null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a session by display id
         * @param {string} displayId display ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionByDisplayId: async (displayId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'displayId' is not null or undefined
            assertParamExists('getSessionByDisplayId', 'displayId', displayId)
            const localVarPath = `/session/display/{display_id}`
                .replace(`{${"display_id"}}`, encodeURIComponent(String(displayId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary join a session
         * @param {string} id session ID
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinSession: async (id: string, xForwardedFor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('joinSession', 'id', id)
            const localVarPath = `/session/{id}/join`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xForwardedFor != null) {
                localVarHeaderParameter['X-Forwarded-For'] = String(xForwardedFor);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary register session
         * @param {CreateSessionRequest} [createSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSession(createSessionRequest?: CreateSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSession(createSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Session
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSession(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSession(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableSession(xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableSession(xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMySession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMySession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a session by display id
         * @param {string} displayId display ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionByDisplayId(displayId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionByDisplayId(displayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary join a session
         * @param {string} id session ID
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinSession(id: string, xForwardedFor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JoinSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinSession(id, xForwardedFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration)
    return {
        /**
         * 
         * @summary register session
         * @param {CreateSessionRequest} [createSessionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession(createSessionRequest?: CreateSessionRequest, options?: any): AxiosPromise<Session> {
            return localVarFp.createSession(createSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Session
         * @param {string} id Session ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSession(id: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteSession(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSession(xForwardedFor?: string, options?: any): AxiosPromise<SessionList> {
            return localVarFp.getAvailableSession(xForwardedFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySession(options?: any): AxiosPromise<SessionList> {
            return localVarFp.getMySession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a session by display id
         * @param {string} displayId display ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionByDisplayId(displayId: string, options?: any): AxiosPromise<Session> {
            return localVarFp.getSessionByDisplayId(displayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary join a session
         * @param {string} id session ID
         * @param {string} [xForwardedFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinSession(id: string, xForwardedFor?: string, options?: any): AxiosPromise<JoinSessionResponse> {
            return localVarFp.joinSession(id, xForwardedFor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @summary register session
     * @param {CreateSessionRequest} [createSessionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public createSession(createSessionRequest?: CreateSessionRequest, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).createSession(createSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Session
     * @param {string} id Session ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public deleteSession(id: string, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).deleteSession(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xForwardedFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public getAvailableSession(xForwardedFor?: string, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).getAvailableSession(xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public getMySession(options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).getMySession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a session by display id
     * @param {string} displayId display ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public getSessionByDisplayId(displayId: string, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).getSessionByDisplayId(displayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary join a session
     * @param {string} id session ID
     * @param {string} [xForwardedFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public joinSession(id: string, xForwardedFor?: string, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).joinSession(id, xForwardedFor, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShortenApi - axios parameter creator
 * @export
 */
export const ShortenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * httpsで始まるstyly.ccのドメインのURLのみ短縮化出来る
         * @summary Create Shorten Url
         * @param {CreateShortenUrlRequest} createShortenUrlRequest url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShortenUrl: async (createShortenUrlRequest: CreateShortenUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShortenUrlRequest' is not null or undefined
            assertParamExists('createShortenUrl', 'createShortenUrlRequest', createShortenUrlRequest)
            const localVarPath = `/shorten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShortenUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortenApi - functional programming interface
 * @export
 */
export const ShortenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortenApiAxiosParamCreator(configuration)
    return {
        /**
         * httpsで始まるstyly.ccのドメインのURLのみ短縮化出来る
         * @summary Create Shorten Url
         * @param {CreateShortenUrlRequest} createShortenUrlRequest url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShortenUrl(createShortenUrlRequest: CreateShortenUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateShortenUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShortenUrl(createShortenUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShortenApi - factory interface
 * @export
 */
export const ShortenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortenApiFp(configuration)
    return {
        /**
         * httpsで始まるstyly.ccのドメインのURLのみ短縮化出来る
         * @summary Create Shorten Url
         * @param {CreateShortenUrlRequest} createShortenUrlRequest url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShortenUrl(createShortenUrlRequest: CreateShortenUrlRequest, options?: any): AxiosPromise<CreateShortenUrlResponse> {
            return localVarFp.createShortenUrl(createShortenUrlRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortenApi - object-oriented interface
 * @export
 * @class ShortenApi
 * @extends {BaseAPI}
 */
export class ShortenApi extends BaseAPI {
    /**
     * httpsで始まるstyly.ccのドメインのURLのみ短縮化出来る
     * @summary Create Shorten Url
     * @param {CreateShortenUrlRequest} createShortenUrlRequest url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortenApi
     */
    public createShortenUrl(createShortenUrlRequest: CreateShortenUrlRequest, options?: AxiosRequestConfig) {
        return ShortenApiFp(this.configuration).createShortenUrl(createShortenUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags: async (createTagsRequest: CreateTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTagsRequest' is not null or undefined
            assertParamExists('createTags', 'createTagsRequest', createTagsRequest)
            const localVarPath = `/tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createTagsDeprecated: async (createTagsRequest: CreateTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTagsRequest' is not null or undefined
            assertParamExists('createTagsDeprecated', 'createTagsRequest', createTagsRequest)
            const localVarPath = `/tag/tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tags
         * @param {number} [limit] Number of tags get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularTags: async (limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tag/popular_item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsByIds: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getTagsByIds', 'ids', ids)
            const localVarPath = `/tag/id_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTags(createTagsRequest: CreateTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTags(createTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createTagsDeprecated(createTagsRequest: CreateTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTagsDeprecated(createTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tags
         * @param {number} [limit] Number of tags get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPopularTags(limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCountResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPopularTags(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsByIds(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagsByIds(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags(createTagsRequest: CreateTagsRequest, options?: any): AxiosPromise<TagList> {
            return localVarFp.createTags(createTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createTagsDeprecated(createTagsRequest: CreateTagsRequest, options?: any): AxiosPromise<TagList> {
            return localVarFp.createTagsDeprecated(createTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tags
         * @param {number} [limit] Number of tags get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularTags(limit?: number, offset?: number, options?: any): AxiosPromise<TagCountResult> {
            return localVarFp.getPopularTags(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsByIds(ids: Array<string>, options?: any): AxiosPromise<TagList> {
            return localVarFp.getTagsByIds(ids, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @summary Creates tags with texts
     * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public createTags(createTagsRequest: CreateTagsRequest, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).createTags(createTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates tags with texts
     * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public createTagsDeprecated(createTagsRequest: CreateTagsRequest, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).createTagsDeprecated(createTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tags
     * @param {number} [limit] Number of tags get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getPopularTags(limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).getPopularTags(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets tags by IDs
     * @param {Array<string>} ids Comma separated IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getTagsByIds(ids: Array<string>, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).getTagsByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates Scene access token
         * @param {string} id Scene ID
         * @param {string} authorization API Key with Bearer keyword.
         * @param {SceneAccessTokenCreationRequest} [sceneAccessTokenCreationRequest] Payload including options to create token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSceneAccessToken: async (id: string, authorization: string, sceneAccessTokenCreationRequest?: SceneAccessTokenCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSceneAccessToken', 'id', id)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createSceneAccessToken', 'authorization', authorization)
            const localVarPath = `/token/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sceneAccessTokenCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates Scene access token
         * @param {string} id Scene ID
         * @param {string} authorization API Key with Bearer keyword.
         * @param {SceneAccessTokenCreationRequest} [sceneAccessTokenCreationRequest] Payload including options to create token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSceneAccessToken(id: string, authorization: string, sceneAccessTokenCreationRequest?: SceneAccessTokenCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneAccessTokenCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSceneAccessToken(id, authorization, sceneAccessTokenCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates Scene access token
         * @param {string} id Scene ID
         * @param {string} authorization API Key with Bearer keyword.
         * @param {SceneAccessTokenCreationRequest} [sceneAccessTokenCreationRequest] Payload including options to create token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSceneAccessToken(id: string, authorization: string, sceneAccessTokenCreationRequest?: SceneAccessTokenCreationRequest, options?: any): AxiosPromise<SceneAccessTokenCreationResponse> {
            return localVarFp.createSceneAccessToken(id, authorization, sceneAccessTokenCreationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * 
     * @summary Creates Scene access token
     * @param {string} id Scene ID
     * @param {string} authorization API Key with Bearer keyword.
     * @param {SceneAccessTokenCreationRequest} [sceneAccessTokenCreationRequest] Payload including options to create token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public createSceneAccessToken(id: string, authorization: string, sceneAccessTokenCreationRequest?: SceneAccessTokenCreationRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).createSceneAccessToken(id, authorization, sceneAccessTokenCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmailWithCode: async (emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailChangeWithCodeRequest' is not null or undefined
            assertParamExists('changeEmailWithCode', 'emailChangeWithCodeRequest', emailChangeWithCodeRequest)
            const localVarPath = `/user/change_email_with_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailChangeWithCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFollowing: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkFollowing', 'id', id)
            const localVarPath = `/user/{id}/following`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        checkFollowingDeprecated: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkFollowingDeprecated', 'id', id)
            const localVarPath = `/user/user/{id}/following`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Withdraw from styly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        follow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('follow', 'id', id)
            const localVarPath = `/user/{id}/follow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        followDeprecated: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('followDeprecated', 'id', id)
            const localVarPath = `/user/user/{id}/follow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets followees of a user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowees: async (id: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFollowees', 'id', id)
            const localVarPath = `/user/{id}/followees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFolloweesDeprecated: async (id: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFolloweesDeprecated', 'id', id)
            const localVarPath = `/user/user/{id}/followees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets followers of a user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowers: async (id: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFollowers', 'id', id)
            const localVarPath = `/user/{id}/followers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFollowersDeprecated: async (id: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFollowersDeprecated', 'id', id)
            const localVarPath = `/user/user/{id}/followers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets myself
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserDeprecated: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDeprecated', 'id', id)
            const localVarPath = `/user/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByIds: async (ids: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getUsersByIds', 'ids', ids)
            const localVarPath = `/user/id_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refreshes API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshApiKey: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/refresh_api_key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (passwordResetRequest: PasswordResetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('resetPassword', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/user/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordWithCode: async (passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetWithCodeRequest' is not null or undefined
            assertParamExists('resetPasswordWithCode', 'passwordResetWithCodeRequest', passwordResetWithCodeRequest)
            const localVarPath = `/user/reset_password_with_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetWithCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches users
         * @param {string} [query] Query to search users.
         * @param {number} [limit] Limit of users to get.
         * @param {number} [offset] Offset of search
         * @param {'follower_count' | 'scene_count' | 'view_count' | 'created_at'} [sort] Sort key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {string} [userFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUser: async (query?: string, limit?: number, offset?: number, sort?: 'follower_count' | 'scene_count' | 'view_count' | 'created_at', direction?: 'asc' | 'desc', userFields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (userFields !== undefined) {
                localVarQueryParameter['user_fields'] = userFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailChangeEmail: async (sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendEmailChangeEmailRequest' is not null or undefined
            assertParamExists('sendEmailChangeEmail', 'sendEmailChangeEmailRequest', sendEmailChangeEmailRequest)
            const localVarPath = `/user/send_email_change_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailChangeEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendEmailResetPasswordRequest} sendEmailResetPasswordRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailResetPassword: async (sendEmailResetPasswordRequest: SendEmailResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendEmailResetPasswordRequest' is not null or undefined
            assertParamExists('sendEmailResetPassword', 'sendEmailResetPasswordRequest', sendEmailResetPasswordRequest)
            const localVarPath = `/user/send_password_reset_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unfollow', 'id', id)
            const localVarPath = `/user/{id}/follow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        unfollowDeprecated: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unfollowDeprecated', 'id', id)
            const localVarPath = `/user/user/{id}/follow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfileRequest} userProfileRequest Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (userProfileRequest: UserProfileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileRequest' is not null or undefined
            assertParamExists('updateProfile', 'userProfileRequest', userProfileRequest)
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload profile image
         * @param {File} body Profile object
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfileImage: async (body: File, contentType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('uploadProfileImage', 'body', body)
            const localVarPath = `/user/profile/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType != null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmailWithCode(emailChangeWithCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkFollowing(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkFollowing(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async checkFollowingDeprecated(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkFollowingDeprecated(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Withdraw from styly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async follow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.follow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async followDeprecated(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.followDeprecated(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets followees of a user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowees(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowees(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getFolloweesDeprecated(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolloweesDeprecated(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets followers of a user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowers(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowers(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getFollowersDeprecated(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFollowersDeprecated(id, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets myself
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getUserDeprecated(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDeprecated(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByIds(ids: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByIds(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refreshes API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshApiKey(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshApiKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshApiKey(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(passwordResetRequest: PasswordResetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(passwordResetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordWithCode(passwordResetWithCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches users
         * @param {string} [query] Query to search users.
         * @param {number} [limit] Limit of users to get.
         * @param {number} [offset] Offset of search
         * @param {'follower_count' | 'scene_count' | 'view_count' | 'created_at'} [sort] Sort key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {string} [userFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUser(query?: string, limit?: number, offset?: number, sort?: 'follower_count' | 'scene_count' | 'view_count' | 'created_at', direction?: 'asc' | 'desc', userFields?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUser(query, limit, offset, sort, direction, userFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailChangeEmail(sendEmailChangeEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendEmailResetPasswordRequest} sendEmailResetPasswordRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailResetPassword(sendEmailResetPasswordRequest: SendEmailResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailResetPassword(sendEmailResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unfollow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unfollow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async unfollowDeprecated(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unfollowDeprecated(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfileRequest} userProfileRequest Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(userProfileRequest: UserProfileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(userProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload profile image
         * @param {File} body Profile object
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProfileImage(body: File, contentType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProfileImage(body, contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.changeEmailWithCode(emailChangeWithCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFollowing(id: number, options?: any): AxiosPromise<FollowCheckResponse> {
            return localVarFp.checkFollowing(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        checkFollowingDeprecated(id: number, options?: any): AxiosPromise<FollowCheckResponse> {
            return localVarFp.checkFollowingDeprecated(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Withdraw from styly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.deleteUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        follow(id: number, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.follow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        followDeprecated(id: number, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.followDeprecated(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets followees of a user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowees(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<UserSearchResult> {
            return localVarFp.getFollowees(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFolloweesDeprecated(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<UserSearchResult> {
            return localVarFp.getFolloweesDeprecated(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets followers of a user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowers(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<UserSearchResult> {
            return localVarFp.getFollowers(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFollowersDeprecated(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<UserSearchResult> {
            return localVarFp.getFollowersDeprecated(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets myself
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<LoginUser> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getUserDeprecated(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.getUserDeprecated(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByIds(ids: Array<number>, options?: any): AxiosPromise<UserList> {
            return localVarFp.getUsersByIds(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refreshes API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshApiKey(options?: any): AxiosPromise<RefreshApiKeyResponse> {
            return localVarFp.refreshApiKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(passwordResetRequest: PasswordResetRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.resetPassword(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.resetPasswordWithCode(passwordResetWithCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches users
         * @param {string} [query] Query to search users.
         * @param {number} [limit] Limit of users to get.
         * @param {number} [offset] Offset of search
         * @param {'follower_count' | 'scene_count' | 'view_count' | 'created_at'} [sort] Sort key
         * @param {'asc' | 'desc'} [direction] Sort order direction
         * @param {string} [userFields] Custom request fields separated by comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUser(query?: string, limit?: number, offset?: number, sort?: 'follower_count' | 'scene_count' | 'view_count' | 'created_at', direction?: 'asc' | 'desc', userFields?: string, options?: any): AxiosPromise<UserSearchResult> {
            return localVarFp.searchUser(query, limit, offset, sort, direction, userFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.sendEmailChangeEmail(sendEmailChangeEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendEmailResetPasswordRequest} sendEmailResetPasswordRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailResetPassword(sendEmailResetPasswordRequest: SendEmailResetPasswordRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.sendEmailResetPassword(sendEmailResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollow(id: number, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.unfollow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        unfollowDeprecated(id: number, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.unfollowDeprecated(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfileRequest} userProfileRequest Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(userProfileRequest: UserProfileRequest, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.updateProfile(userProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload profile image
         * @param {File} body Profile object
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProfileImage(body: File, contentType?: string, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.uploadProfileImage(body, contentType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Changes account email with code.
     * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changeEmailWithCode(emailChangeWithCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check following or not.
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkFollowing(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).checkFollowing(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check following or not.
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkFollowingDeprecated(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).checkFollowingDeprecated(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Withdraw from styly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Follows a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public follow(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).follow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Follows a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public followDeprecated(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).followDeprecated(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets followees of a user
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFollowees(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getFollowees(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets followees of a login user
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFolloweesDeprecated(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getFolloweesDeprecated(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets followers of a user
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFollowers(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getFollowers(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets followers of a login user
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFollowersDeprecated(id: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getFollowersDeprecated(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets myself
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMe(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserDeprecated(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserDeprecated(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets users by IDs
     * @param {Array<number>} ids Comma separated IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersByIds(ids: Array<number>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsersByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refreshes API key of a login user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public refreshApiKey(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).refreshApiKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets login user\'s password
     * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPassword(passwordResetRequest: PasswordResetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).resetPassword(passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets password with validation code
     * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).resetPasswordWithCode(passwordResetWithCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches users
     * @param {string} [query] Query to search users.
     * @param {number} [limit] Limit of users to get.
     * @param {number} [offset] Offset of search
     * @param {'follower_count' | 'scene_count' | 'view_count' | 'created_at'} [sort] Sort key
     * @param {'asc' | 'desc'} [direction] Sort order direction
     * @param {string} [userFields] Custom request fields separated by comma.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchUser(query?: string, limit?: number, offset?: number, sort?: 'follower_count' | 'scene_count' | 'view_count' | 'created_at', direction?: 'asc' | 'desc', userFields?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).searchUser(query, limit, offset, sort, direction, userFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends email change verification email.
     * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).sendEmailChangeEmail(sendEmailChangeEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends password reset email.
     * @param {SendEmailResetPasswordRequest} sendEmailResetPasswordRequest Payload with email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendEmailResetPassword(sendEmailResetPasswordRequest: SendEmailResetPasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).sendEmailResetPassword(sendEmailResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unfollows a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public unfollow(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).unfollow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unfollows a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public unfollowDeprecated(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).unfollowDeprecated(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates profile of a user
     * @param {UserProfileRequest} userProfileRequest Profile object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateProfile(userProfileRequest: UserProfileRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateProfile(userProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload profile image
     * @param {File} body Profile object
     * @param {string} [contentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadProfileImage(body: File, contentType?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).uploadProfileImage(body, contentType, options).then((request) => request(this.axios, this.basePath));
    }
}


