import React from "react"
import { tracker, tunnelClient, } from "globalInstance"

export function useTunnelClient () {
  const playScene = React.useCallback(async (sceneId: string) => {
    await tunnelClient.playScene(sceneId)
    tracker.playScene(sceneId)
  }, [])

  return {
    playScene,
  }
}
