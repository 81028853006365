import React, { useState, useEffect, useCallback, } from "react"
import classnames from "classnames"
import AccountIcon from "features/AccountIcon"
import style from "./style.module.scss"
import PickupSceneListModel from "utils/contentful/PickupSceneList"
import PickupSceneList from "../PickupSceneList"
import PickupScene from "models/PickupScene"
import { useTunnelClient, } from "hooks/TunnelClient"

/**
 * Hard-coded model of pickup scene list.
 */
const staffPicksList = {
  id: "staffPicks",
  priority: 0,
  title: "Staff Picks",
  description: "",
  searchQuery: "",
  labelImage: null,
  scenes: [],
} as PickupSceneListModel

type Props = {
  // scenes including scenes shown in PickupSceneList component.
  // The first 5 scenes are shown in the billboard style.
  scenes: PickupScene[],
}

/**
 * The large billboard UI component placed in the top page.
 * This component includes PickupSceneList component in it.
 */
export default function Billboard ({
  scenes,
}: Props) {
  const [index, setIndex] = useState(0)
  const [show, setShow] = useState(true)
  const [timer, setTimer] = useState<number | null>(null)
  const [transitAuto, setTransitAuto] = useState(true)
  const { playScene, } = useTunnelClient()

  const billboardScenes = scenes.slice(0, 5)
  const scene = billboardScenes[index]

  const onClickInternal = useCallback(() => {
    playScene(scene.id)
  }, [scene, playScene])

  const go = useCallback((index: number, interacted: boolean) => {
    setIndex(index)
    setShow(false)
    setTimeout(() => {
      setShow(true)
    }, 200)
    if (interacted) {
      setTransitAuto(false)
      if (timer) {
        window.clearTimeout(timer)
      }
    }
  }, [timer])

  useEffect(() => {
    if (transitAuto && timer == null) {
      const newTimer = window.setTimeout(() => {
        if (index === billboardScenes.length - 1) {
          go(0, false)
        } else {
          go(index + 1, false)
        }
        setTimer(null)
      }, 5000)
      setTimer(newTimer)
    }
  }, [index, billboardScenes.length, go, transitAuto, timer])

  if (scenes.length === 0) {
    return <div />
  }

  return (
    <div
      className={classnames({
        [style.frame]: true,
        [style.show]: show,
      })}
    >
      <div
        className={style.image}
        onClick={onClickInternal}
        style={{ backgroundImage: `url(${scene.image.url})`, }}
      >
        <div className={style.cover} />
      </div>
      <div className={style.inner}>
        <div className={style.meta}>
          <div className={style.title}>{scene.title}</div>
          <div className={style.user}>
            <div className={style.iconContainer}>
              <AccountIcon
                username={scene.user.username}
                profileImageUrl={scene.user.profileImageUrl || ""}
              />
            </div>
            <div className={style.text}>{scene.user.username}</div>
          </div>
          <div className={style.description}>{scene.description}</div>
        </div>
        {scenes && scenes.length > 0 && (
          <div className={style.staffPicksContianer}>
            <PickupSceneList
              list={staffPicksList}
              scenes={scenes}
            />
          </div>
        )}
      </div>
      <div className={style.barContainer}>
        {billboardScenes.map((s, i) => {
          return (
            <div
              key={s.id}
              className={classnames({
                [style.barInteractiveBox]: true,
                [style.selected]: i === index,
              })}
              onClick={(e) => { e.stopPropagation(); go(i, true) }}
            >
              <div className={style.bar} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
