import React from "react"
import { notifyAppInitialized, initialize, slice, selectState, } from "./redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import MarkerPopup from "features/MarkerPopup"
import TopPage from "pages/TopPage"
import OnboardingPage from "pages/OnboardingPage"
import { Route, Switch, } from "react-router-dom"
import style from "./style.module.scss"
import history from "utils/history"
import { tunnelClient, } from "globalInstance"

const mapDispatch = { notifyAppInitialized, initialize, ...slice.actions, }
const connector = connect(null, mapDispatch)

type Props = ConnectedProps<typeof connector>

function App ({
  hideMarker,
  notifyAppInitialized,
  initialize,
  markOnboardingPageVisited,
}: Props) {
  const {
    markerSceneId,
    markerSceneTitle,
    markerSceneThumbnailUrl,
    initializing,
    initialized,
    initializeError,
    unityConnectionEstablished,
    unityConnectionEstablishError,
    firstLaunch,
    onboardingPageVisited,
    me,
  } = useSelector(selectState)

  const hideMarkerInternal = () => {
    hideMarker()
  }
  const onShareOnMarker = async () => {
    if (markerSceneId && markerSceneTitle) {
      await tunnelClient.shareScene(markerSceneId, markerSceneTitle)
    }
  }

  React.useEffect(() => {
    notifyAppInitialized()
  }, [notifyAppInitialized])

  React.useEffect(() => {
    // 初期化処理を実行
    if (unityConnectionEstablished && !initializeError && !initializing && !initialized) {
      initialize()
    }
  }, [unityConnectionEstablished, initializeError, initializing, initialized, initialize])

  React.useEffect(() => {
    if (unityConnectionEstablished && initialized) {
      if (firstLaunch && !onboardingPageVisited) {
        history.push("/onboarding")
        markOnboardingPageVisited()
      }
    }
  }, [unityConnectionEstablished, initialized, firstLaunch, markOnboardingPageVisited, onboardingPageVisited, me])

  // Unity との通信経路が確保できなかった場合エラー表示
  if (unityConnectionEstablishError) {
    return (<div>Backend Communication Error</div>)
  }

  // 初期化処理に失敗した場合は要素を表示せず何も操作をさせない
  if (initializeError === true) {
    return (<div>Initialization Error</div>)
  }

  // 初期化処理が終了するまでは要素を出力しない
  if (!unityConnectionEstablished || !initialized) {
    return (<></>)
  }

  return (
    <div className={style.frame}>
      <Switch>
        <Route path="/onboarding" component={OnboardingPage} />
        <Route path="/" component={TopPage} />
      </Switch>
      <MarkerPopup
        open={!!markerSceneId}
        onClose={hideMarkerInternal}
        sceneId={markerSceneId || ""}
        onShare={onShareOnMarker}
        thumbnailUrl={markerSceneThumbnailUrl || ""}
      />
    </div>
  )
}

export default connector(App)
