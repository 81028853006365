import { createSlice, PayloadAction, createAsyncThunk, } from "@reduxjs/toolkit"
import { RootState, } from "app/store"
import { REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import Me from "utils/api/models/Me"
import axios from "axios"
import ApiClient from "utils/api"
import { API_BASE_PATH, } from "app/env"
import { tunnelClient, tracker, } from "globalInstance"
import i18nInit from "utils/i18n"
import { getLocale, } from "utils/locale"
import history from "utils/history"

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/App`

export const notifyAppInitialized = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/notifyAppInitialized`,
  async () => {
    return new Promise<{ firstLaunch: boolean, service: "STYLY_MOBILE" | "SATCH", platform: "IOS" | "ANDROID" }>((resolve, reject) => {
      const intervalId = setInterval(async () => {
        if (tunnelClient.ready()) {
          clearInterval(intervalId)
          clearTimeout(timeoutId)
          try {
            const res = await tunnelClient.notifyAppInitialized()
            tracker.initialize(res.debug)
            resolve(res)
          } catch (e) {
            reject(e)
          }
        }
      }, 100)
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId)
        reject(new Error("Timeout checking tunnelClient.ready()"))
      }, 5_000)
    })
  }
)

/**
 * 初期化処理
 */
export const initialize = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async () => {
    tunnelClient.setNavigatorBackCallback(() => {
      const hasHistory = history.length > 1
      if (hasHistory) {
        history.goBack()
      }
      if (window.location.pathname.startsWith("/login")) {
        history.push("/")
        return true
      }
      return hasHistory
    })
    const locale = getLocale()
    i18nInit(locale)
    // ログインユーザの情報を取得
    const apiClient = new ApiClient(API_BASE_PATH)
    let user = null
    try {
      user = await apiClient.getMe()
      await tunnelClient.notifyLogin()
    } catch (e) {
      if (axios.isAxiosError(e) && e.response && e.response.status === 401) {
        // 401エラーは未ログイン時に発生するため通知しない
        return user
      }
      throw e
    }
    return user
  }
)

/**
 * Deletes browsing history by asking Unity to do it.
 */
export const deleteBrowingHistory = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/deleteBrowingHistory`,
  async () => {
    await tunnelClient.deleteHistory()
  }
)

export const slice = createSlice({
  name: ACTION_TYPE_PREFIX,
  initialState: {
    markerSceneId: null as (string | null),
    markerSceneTitle: null as (string | null),
    markerSceneThumbnailUrl: null as (string | null),
    me: null as (Me | null),
    authErrorMessage: null as (string | null),
    // Unity との通信確立されたかどうか
    unityConnectionEstablishing: false,
    unityConnectionEstablished: false,
    unityConnectionEstablishError: false,
    firstLaunch: false,
    onboardingPageVisited: false,
    // 初期化関連のフラグ
    initializing: false as boolean,
    initialized: false as boolean,
    initializeError: false as boolean,
    // ユーザの操作を受け付けない処理を実行中の場合にtrue
    intaractionRestricted: false as boolean,
    // STYLY_MOBILE | SATCH
    service: null as null | "STYLY_MOBILE" | "SATCH",
    // IOS | ANDROID
    platform: null as null | "ANDROID" | "IOS",
  },
  reducers: {
    showMarker: (state, action: PayloadAction<{ id: string, title: string, thumbnailUrl: string}>) => {
      const { id, title, thumbnailUrl, } = action.payload
      return {
        ...state,
        markerSceneId: id,
        markerSceneTitle: title,
        markerSceneThumbnailUrl: thumbnailUrl,
      }
    },
    hideMarker: (state) => {
      return {
        ...state,
        markerSceneId: null,
        markerSceneThumbnailUrl: null,
      }
    },
    markOnboardingPageVisited: (state) => {
      state.onboardingPageVisited = true
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        notifyAppInitialized.pending,
        (state) => {
          state.unityConnectionEstablishing = true
        })
      .addCase(
        notifyAppInitialized.fulfilled,
        (state, action) => {
          state.unityConnectionEstablishing = true
          state.unityConnectionEstablished = true
          state.unityConnectionEstablishError = false
          state.firstLaunch = action.payload.firstLaunch
          state.service = action.payload.service
          state.platform = action.payload.platform
        })
      .addCase(
        notifyAppInitialized.rejected,
        (state) => {
          state.unityConnectionEstablishing = true
          state.unityConnectionEstablished = false
          state.unityConnectionEstablishError = true
        })
      .addCase(
        initialize.pending,
        (state) => {
          state.initializing = true
          state.initialized = false
          state.intaractionRestricted = true
        })
      .addCase(
        initialize.rejected,
        (state) => {
          state.initializing = false
          state.initializeError = true
          state.intaractionRestricted = false
        })
      .addCase(
        initialize.fulfilled,
        (state, action) => {
          state.initializing = false
          state.initialized = true
          state.me = action.payload
          state.intaractionRestricted = false
        })
  },
})

export const selectState = (state: RootState) => state.app

export default slice.reducer
