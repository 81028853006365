import { configureStore, getDefaultMiddleware, } from "@reduxjs/toolkit"
import appReducer from "./App/redux"
import topPageReducer from "pages/TopPage/redux"
import logger from "redux-logger"
import { ENABLE_REDUX_LOGGER, } from "./env"

let middleware = getDefaultMiddleware()
if (ENABLE_REDUX_LOGGER) {
  middleware = middleware.concat(logger)
}

const store = configureStore({
  middleware: middleware,
  reducer: {
    app: appReducer,
    topPage: topPageReducer,
  },
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
