import React, { useCallback, } from "react"
import style from "./style.module.scss"
import { TopAd, } from "utils/contentful/TopAd"

type Props = {
  ads: TopAd[],
  onAdClick: (url: string) => void
}

/**
 * List of Ad blocks placed in the top page.
 */
export default function AdList ({ ads, onAdClick, }: Props) {
  const onAdClickInternal = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.dataset.url && onAdClick) {
      onAdClick(e.currentTarget.dataset.url)
    }
  }, [onAdClick])
  return (
    <div className={style.frame}>
      <div className={style.inner}>
        {ads.map(ad => {
          if (!ad.image) {
            return null
          }
          return (
            <div
              key={ad.id}
              className={style.adContainer}
              data-url={ad.destinationUrl}
              onClick={onAdClickInternal}>
              <img key={ad.id} className={style.ad} src={ad.image.url} alt="ad"/>
            </div>
          )
        })}
      </div>
    </div>
  )
}
